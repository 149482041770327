import { Action, State, StateContext } from '@ngxs/store';
import { GradingStateModel } from './grading-state.model';
import {
  ResetGrading,
  SetActiveGradingStation,
  SetGradingCharacteristics,
  SetGradingItem,
  SetGradingProduct,
  SetStockPopulationData,
} from './grading.actions';
import { Injectable } from '@angular/core';

@State<GradingStateModel>({
  name: 'grading',
})
@Injectable()
export class GradingState {
  @Action(SetStockPopulationData)
  public set(ctx: StateContext<GradingStateModel>, { stock }: SetStockPopulationData): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      stock,
    });
  }

  @Action(SetGradingProduct)
  public setProduct(ctx: StateContext<GradingStateModel>, { product }: SetGradingProduct): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      product,
    });
  }

  @Action(SetGradingCharacteristics)
  public setCharacteristics(
    ctx: StateContext<GradingStateModel>,
    { characteristics }: SetGradingCharacteristics,
  ): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      characteristics,
    });
  }

  @Action(SetGradingItem)
  public setGrading(ctx: StateContext<GradingStateModel>, { grading }: SetGradingItem): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      grading,
    });
  }

  @Action(SetActiveGradingStation)
  public setActiveGradingSessionStation(
    ctx: StateContext<GradingStateModel>,
    { gradingStation }: SetActiveGradingStation,
  ): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      gradingStation,
    });
  }

  @Action(ResetGrading)
  public reset(ctx: StateContext<GradingStateModel>): void {
    ctx.setState({});
  }
}
